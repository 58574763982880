<template>
  <div class="thanks-page-content text-center mx-auto">
    <div class="thank-you-text">
      <p class="font-weight-bold fs-24 mb-0">
        Thank you {{ vehicleInfo.customerName }} for your enquiry on AutoFlip.
      </p>
    </div>
    <div class="body-content">
      <p class="section-header font-weight-bold fs-24">What to expect next?</p>
      <ul class="desc">
        <li
          v-for="(item, index) in whatToExpectNextContent"
          :key="index"
          class="font-weight-thin"
        >
          {{ item }}
        </li>
      </ul>
      <p
        class="section-header font-weight-bold fs-24"
        :class="isMobile ? 'mobile-bg' : ''"
      >
        Your car details
      </p>
      <div class="bg-white pt-15 main-content">
        <!-- <p class="mb-2 main-content-title">YOUR CAR INFORMATION</p> -->
        <div
          class="d-flex justify-content-center align-items-center item mx-auto"
        >
          <p class="mb-0">{{ vehicleInfo.year }} {{ vehicleInfo.make }} {{ vehicleInfo.model }}</p>
        </div>
        <vehicle-slide></vehicle-slide>
        <div class="vehicle mx-auto">
          <vehicle-condition :vehicleInfo="vehicleInfo"></vehicle-condition>
          <vehicle-detail :vehicleInfo="vehicleInfo"></vehicle-detail>
        </div>
        <div class="text-note px-2 mb-0">
          Something not quite right?<br />
          <span
            >No problem, simply email
            <a
              :href="`mailto:${contactDetails.email}`"
              style="color: inherit; text-decoration: underline;"
              >{{ contactDetails.email }}</a
            >
            and we'll update your info.</span
          >
        </div>
      </div>
    </div>
    <how-we-work></how-we-work>
  </div>
</template>

<script>
import VehicleSlide from "@/components/thanks-page/VehicleSlide";
import VehicleCondition from "@/components/thanks-page/VehicleCondition";
import VehicleDetail from "@/components/thanks-page/VehicleDetails";
import HowWeWork from "@/components/thanks-page/HowWeWork";
import { mapState } from "vuex"

export default {
  name: "Index",
  data() {
    return {
      vehicleInfo: { ...this.$store.getters.curVehicleInfo },
      isMobile: true,
      valuation: {},
    };
  },
  computed: {
    ...mapState({
      contactDetails: (state) => state.commonVariables.contactDetails,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1000px)");
    },
    isNotSelling: function() {
      return this.vehicleInfo.sell === "I'm not selling";
    },
    fromPrice() {
      return "$" + new Intl.NumberFormat().format(this.vehicleInfo.from);
    },
    toPrice() {
      return "$" + new Intl.NumberFormat().format(this.vehicleInfo.to);
    },
    discretePrice() {
      return "$" + new Intl.NumberFormat().format(this.vehicleInfo.discreteValue);
    },
    hasGotPrice: function() {
      return this.vehicleInfo.from > 0 && this.vehicleInfo.to > 0;
    },
    whatToExpectNextContent: function() {
      return !this.isNotSelling
        ? [
            "Before your car is officially listed for sale, a member of our customer service team will contact you within the next business day.",
            "They'll confirm a few details, advise you on valuing your car, answer any questions and then go to work to get you the best price, fast.",
            "In the meantime, you can continue updating your details by clicking the link provided in the confirmation email you'll receive shortly.",
          ]
        : [
            "A friendly team member from AutoFlip may call you to check if they can assist with anything for your car.",
            "If you change your mind and decide to sell your car, continue updating your details by clicking the link provided in the confirmation email you'll receive shortly.",
          ];
    },
  },
  methods: {
    // goBack() {
    //   this.$router.push({name: 'vehicle-info'})
    // }
  },
  components: {
    VehicleSlide,
    VehicleCondition,
    VehicleDetail,
    HowWeWork,
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.thanks-page-content {
  color: #404040;

  .thank-you-text {
    background-color: #f9fafb;
    padding: 20px;
  }

  .body-content {
    margin-bottom: 30px;

    .section-header {
      color: #1f8252;
      text-transform: uppercase;
      margin: 1rem 0;
      &.mobile-bg {
        background-color: #f9fafb;
        padding: 1rem 0;
      }
    }
    .desc {
      padding-inline-start: 35px;
      padding-right: 20px;
      li {
        text-align: left;
      }
    }

    .main-content {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .main-content-title {
        font-size: 24px;
        font-family: Avenir Next LT W05 Demi;
        font-weight: 400;
      }

      .item {
        width: fit-content;
        height: 40px;
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 0 12px;
      }

      .text-note {
        color: #74bd58;
        font-size: 14px;
        text-align: left;
        max-width: 354px;
        margin-top: 17px;
        margin-left: auto;
        margin-right: auto;

        span {
          color: black !important;
        }
      }
    }
  }
  .lean-info {
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
}

@media screen and(min-width: 576px) {
  .body-content {
    max-width: 570px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .thanks-page-content {
    .thank-you-text p {
      margin: 0 auto;
      max-width: 800px;
    }
    .body-content {
      max-width: 720px;
    }
    .main-content {
      padding-bottom: 30px !important;
      .vehicle {
        max-width: 980px;
        display: flex;
        justify-content: space-between;
      }
      .text-note {
        max-width: 515px !important;
        margin-top: 17px !important;
        padding: 0 !important;
      }
      .item {
        padding: 0px 22px !important;
      }
    }
    .lean-info {
      margin: 0;
      width: 100%;
      max-width: unset;
      .title-info {
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content-detail-tittle{
    font-size: 20px !important;
  }

  .main-content-title {
    font-size: 20px !important;
  }

  .car-note {
    text-align: left !important;
  }
  
  .autoflip-price {
    max-width: 303px !important;

    .no-valuation {
      font-size: 20px !important;
    }
  }
  
  .no-price {
    max-width:303px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .title-content {
    .top-head {
      margin-bottom: 0rem !important;
      font-size: 22px !important;
      padding-top: 12px !important;
    }
    .desc {
      margin-top: 2px !important;
      .bottom-head {
        font-size: 22px !important;
        margin-bottom: 10px !important;
      }
      .desc-content {
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 450px) and (orientation: portrait){
  .top-head {
    padding: 0 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .main-content {
    .vehicle-non-slide {
      max-width: 600px !important;
    }
    .vehicle-slide {
      max-width: 625px !important;
    }
    .vehicle {
      display: block !important;
      justify-content: unset !important;
      .vehicle-condition {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 515px !important;
        .vehicle-condition-wrapper {
          flex-direction: column !important;
          .vehicle-condition-item {
            margin: 0 auto !important;
            transform: scale(1.1);
          }
        }
      }
      .vehicle-detail {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 515px !important;
        margin-top: 50px !important;
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .vehicle {
    .vehicle-condition {
      margin-left: 5px !important;
    }
    .vehicle-detail {
      margin-right: 5px !important;
    }
  }
}
@media screen and(min-width: 900px) {
  .thanks-page-content {
    .body-content {
      max-width: 960px !important;
      .desc {
        max-width: 620px;
        margin: 0 auto;
      }
    }
  }
}
@media screen and(min-width: 990px) {
  .thanks-page-content {
    .main-content {
      .text-note {
        margin-left: 0 !important;
      }
    }
  }
}
@media screen and(min-width: 1200px) {
  .thanks-page-content {
    padding: 0 10px;
    .body-content {
      max-width: 1120px;
      .desc {
        max-width: 800px;
      }
    }
    .lean-info .type-info {
      max-width: 1050px !important;
      margin: 0 auto;
    }
  }
}
</style>
