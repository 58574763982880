<template>
  <div class="vehicle-detail text-center mx-auto">
    <p class="fs-20 vehicle-detail-title"><b>Car details</b></p>
    <div v-for="(vehicleDetail, index) in vehicleDetails" :key="index"
        class="d-flex justify-content-between align-items-center vehicle-detail-item fs-14 text-left">
      <p class="vehicle-label ml-10 mb-0 font-weight-thin">{{ vehicleDetail.label }}:</p>
      <p v-if="vehicleDetail.key === 'color'" class="mb-0 vehicle-item">
        <b>{{ capitalizeFirstLetter(vehicleInfo[vehicleDetail.key]) }}</b>
      </p>
      <p v-else-if="vehicleDetail.key === 'kms'" class="mb-0 vehicle-item">
        <b>{{ formatKm(vehicleInfo[vehicleDetail.key]) }}</b>
      </p>
      <!-- DMV-191 -->
      <p v-else-if="vehicleDetail.key === 'shift'" class="mb-0 vehicle-item">
        <b>{{ convertShiftValue(vehicleInfo[vehicleDetail.key]) }}</b>
      </p>
      <p v-else-if="vehicleDetail.key === 'badge'" class="mb-0 vehicle-item">
        <b>{{ convertBadgeEdition(vehicleInfo[vehicleDetail.key]) }}</b>
      </p>
      <!-- DMV-191 -->
      <p v-else class="mb-0 vehicle-item">
        <b>{{ vehicleInfo[vehicleDetail.key] }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import {VEHICLE_DETAILS} from "@/config/constants"
import mixin from "@/mixins/common"
import { formatNumberWithComma } from "@/helper/utils"

export default {
  name: "VehicleDetail",
  props: ['vehicleInfo'],
  mixins: [mixin],
  data() {
    return {
      vehicleDetails: [...VEHICLE_DETAILS]
    }
  },
  methods: {
    formatKm(val) {
      if (val) {
        return formatNumberWithComma(val) + ' km'
      }
      return val
    },
    // DMV-191
    convertShiftValue(val) {
      if (val === 'AT') {
        return val.replace('AT', 'Automatic');
      }else if (val === 'MT') {
        return val.replace('MT', 'Manual');
      }
      return val;
    },
    // DMV-191
    convertBadgeEdition(val) {
      if (val && val !== 'Not sure') {
        return val + ' ' + this.vehicleInfo.edition;
      } else if (val === '') {
        return 'N/A';
      }
      return val;
    },
  },
  mounted() {
    if (this.vehicleInfo.model_year === '') {
      this.vehicleDetails.pop()
    }
  }
}
</script>

<style lang="scss">
.vehicle-detail {
  max-width: 354px;
  padding: 15px 20px 30px;
  margin-top: 41px;
  border: 1px solid #CBCBCB;
  border-radius: 20px;

  .vehicle-detail-title {
    margin-bottom: 25px;
  }

  .vehicle-detail-item {
    border-bottom: 1px solid #E6E6E6;
    height: 40px;

    .vehicle-item {
      margin-right: 10px;
      text-transform: uppercase;
      text-align: right;
    }

    &:first-of-type {
      border-top: 1px solid #E6E6E6;
    }

    .vehicle-label {
      width: 115px;
    }
  }
}

@media screen and (min-width: 768px) {
  .vehicle-detail {
    width: calc(50% - 15px) !important;
    max-width: 475px !important;
    min-height: 100% !important;
    padding: 16px 44px 0 !important;
    margin: 0 !important;

    .vehicle-detail-item .vehicle-label {
      width: 159px !important;
    }
  }
}
</style>
